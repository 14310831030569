html {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* =================== */
/* ===== PRESETS ===== */
/* =================== */

.img-fluid {
	max-width: 100%;
	height: auto;
}

.full-background {
	min-height: 100vh;
}

.full-width {
	width: 100%;
}

.half-width {
	width: 50%;
}

.push-top {
	padding-top: 80px;
}

.remove-extra {
	margin-top: -20px;
}

.push-bottom {
	padding-bottom: 140px;
}

.push-sides {
	padding-left: 10vw;
	padding-right: 10vw;
}

.m-auto {
	margin: 0 auto;
}

.m-0 {
	margin: 0 !important;
}

.ml-1 {
	margin-left: 10px !important;
}

.ml-2 {
	margin-left: 20px !important;
}

.ml-3 {
	margin-left: 30px !important;
}

.ml-4 {
	margin-left: 40px !important;
}

.ml-5 {
	margin-left: 50px !important;
}

.mr-1 {
	margin-right: 10px !important;
}

.mr-2 {
	margin-right: 20px !important;
}

.mr-3 {
	margin-right: 30px !important;
}

.mr-4 {
	margin-right: 40px !important;
}

.mr-5 {
	margin-right: 50px !important;
}

.pl-1 {
	padding-left: 10px !important;
}

.pl-2 {
	padding-left: 20px !important;
}

.pl-3 {
	padding-left: 30px !important;
}

.pl-4 {
	padding-left: 40px !important;
}

.pl-5 {
	padding-left: 50px !important;
}

.pr-1 {
	padding-right: 10px !important;
}

.pr-2 {
	padding-right: 20px !important;
}

.pr-3 {
	padding-right: 30px !important;
}

.pr-4 {
	padding-right: 40px !important;
}

.pr-5 {
	padding-right: 50px !important;
}

.mr-0 {
	margin-right: auto;
}

.ml-0 {
	margin-left: auto;
}

.mt-1 {
	margin-top: 10px !important;
}

.mt-2 {
	margin-top: 20px !important;
}

.mt-3 {
	margin-top: 30px !important;
}

.mt-4 {
	margin-top: 40px !important;
}

.mt-5 {
	margin-top: 50px !important;
}

.mb-1 {
	margin-bottom: 10px;
}

.mb-2 {
	margin-bottom: 20px;
}

.mb-3 {
	margin-bottom: 30px;
}

.mb-4 {
	margin-bottom: 40px;
}

.mb-5 {
	margin-bottom: 50px;
}

.my-1 {
	margin-top: 10px;
	margin-bottom: 10px;
}

.my-2 {
	margin-top: 20px;
	margin-bottom: 20px;
}

.my-3 {
	margin-top: 30px;
	margin-bottom: 30px;
}

.my-4 {
	margin-top: 40px;
	margin-bottom: 40px;
}

.my-5 {
	margin-top: 50px;
	margin-bottom: 50px;
}

.mx-1 {
	margin-left: 10px;
	margin-right: 10px;
}

.mx-2 {
	margin-left: 20px;
	margin-right: 20px;
}

.mx-3 {
	margin-left: 30px;
	margin-right: 30px;
}

.mx-4 {
	margin-left: 40px;
	margin-right: 40px;
}

.mx-5 {
	margin-left: 50px;
	margin-right: 50px;
}

.pt-1 {
	padding-top: 10px;
}

.pt-2 {
	padding-top: 20px;
}

.pt-3 {
	padding-top: 30px;
}

.pt-4 {
	padding-top: 40px;
}

.pt-5 {
	padding-top: 50px;
}

.pb-1 {
	padding-bottom: 1rem;
}

.pb-2 {
	padding-bottom: 2rem;
}

.pb-3 {
	padding-bottom: 3rem;
}

.pb-4 {
	padding-bottom: 4rem;
}

.pb-5 {
	padding-bottom: 5rem;
}

.pb-6 {
	padding-bottom: 6rem;
}

.pb-7 {
	padding-bottom: 100px;
}

.py-1 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.py-2 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.py-3 {
	padding-top: 30px;
	padding-bottom: 30px;
}

.py-4 {
	padding-top: 40px;
	padding-bottom: 40px;
}

.py-5 {
	padding-top: 50px;
	padding-bottom: 50px;
}

.px-1 {
	padding-left: 10px;
	padding-right: 10px;
}

.px-2 {
	padding-left: 20px;
	padding-right: 20px;
}

.px-3 {
	padding-left: 30px;
	padding-right: 30px;
}

.px-4 {
	padding-left: 40px;
	padding-right: 40px;
}

.px-5 {
	padding-left: 50px;
	padding-right: 50px;
}

.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.align-center {
	text-align: center;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-justify {
	text-align: justify;
}

.font-weight-400 {
	font-weight: 400;
}

.font-weight-500 {
	font-weight: 500;
}

.font-weight-boldish {
	font-weight: 600;
}

.font-weight-bold {
	font-weight: bold;
}

.font-weight-black {
	font-weight: 900;
}

.t-red {
	color: red;
}

.t-white {
	color: white;
}

.t-lgrey {
	color: #a1a8ac;
}

.t-grey {
	color: #433d3e;
}
.t-blk {
	color: black;
}

.t-yellow {
	color: #fecc59;
}

.t-blue {
	color: #18244D;
}

.xxs-t {
	font-size: 0.625rem; 
}

.xs-t {
	font-size: 0.75rem;
}

.small-t {
	font-size: 0.875rem;
}

.med-t {
	font-size: 1rem;
}

.large-t {
	font-size: 1.125rem;
}

.xl-t {
	font-size: 1.25rem;
}

.xxl-t {
	font-size: 1.5rem;
}

.title-text {
	font-size: 32px;
}

.center-grid {
	display: grid;
	place-items: center;
}

.d-flex {
	display: flex !important;
}

.d-block {
	display: block;
}

.d-inline {
	display: inline;
}

.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-center {
	align-content: center;
}

.justify-between {
	justify-content: space-between !important;
}

.justify-center {
	justify-content: center;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-center {
	align-items: center;
}

.items-flex-end {
	align-items: flex-end;
}

.self-flex-end {
	align-self: flex-end;
}

.flex-1 {
	flex: 1;
}

.cursor-pointer {
	cursor: pointer;
}

.no-wrap {
	white-space: nowrap;
}

.fit-contain {
	object-fit: contain;
}

.t-underline {
	text-decoration: underline;
}

.no-underline {
	text-decoration: none;
}

.pos-abs {
	position: absolute;
}

.pos-rel {
	position: relative;
}

.uppercase {
	text-transform: uppercase;
}

.justify-end {
	justify-content: flex-end;
}

.line-1 {
	line-height: 1;
}

.line-1-2 {
	line-height: 1.2;
}