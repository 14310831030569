* {
  z-index: 1;
}
.App {
  text-align: center;
  max-width: 500px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
}

.form-submit-btn {
  height: 50px;
  font-weight: 700;
  width: 100% !important;
  /* background: #aa1e2e; */
  /* color: black; */
  border-radius: 500px;
  border: none;
  font-size: 20px;
}

.form-submit-btn.purple {
  background: #513a93;
}

.form-submit-btn.grey {
  background: #dfd9cf !important;
  color: #7e7e7e;
}

.login-form {
  width: 85%;
  margin: 0 auto;
}

.form-input,
.form-input:hover,
.form-input:focus,
.form-input:focus-within {
  height: 50px;
  background-color: transparent;
  border-radius: 500px;
  padding: 12px;
  color: #fff;
  border: 1px solid rgba(234, 202, 104, 1);
  /* margin-top: 10px; */
  /* border-image: linear-gradient(rgba(234, 202, 104, 1), rgba(253, 247, 213, 1), rgba(240, 185, 51, 1));
	/* border-width: 1px;
	border-style: solid; */
  /* border: 1px solid linear-gradient(rgba(234, 202, 104, 1), rgba(253, 247, 213, 1), rgba(240, 185, 51, 1)); */
}

.form-input::placeholder {
  color: #bcbcbc;
}

.phone-input-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.prefix-circle {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  color: white;
  border: 1px solid rgba(234, 202, 104, 1);
  border-radius: 50%;
  background-color: transparent;
  z-index: 10;
}

.success-btn {
  background-color: #02bc7d;
  border-radius: 24px;
  height: 40px;
  width: 90%;
  color: white;
  font-weight: 700;
}

.upload-receipt-input-file-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e1d49;
  /* color: white; */
  border-radius: 10px;
  height: 50vh;
  margin: 10px auto;
  width: 85%;
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13);
  position: relative;
  font-weight: 700;
}

.reward-card {
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.25);
  padding: 15px;
  background-color: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.copied {
  position: fixed;
  top: 40%;
  right: 20%;
  background-color: rgba(18, 16, 16, 0.85);
  /* opacity: .7; */
  width: 55%;
  margin: 0 auto;
  padding: 20px 10px;
  border-radius: 10px;
  z-index: 10;
}

/* ANTD STYLING */
.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background-color: transparent !important;
}

.ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
  border-radius: 50%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fecc59;
  border-color: #fecc59;
  color: #332e7c !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid #332e7c;
  border-top: 0;
  border-inline-start: 0;
}

/* csynb */
.golden-gradient {
  background-image: linear-gradient(
    to right,
    rgba(234, 202, 104, 1),
    rgba(253, 247, 213, 1),
    rgba(240, 185, 51, 1)
  );
  color: #18244d;
}

.main-background-image {
  background-image: url("./assets/backgrounds/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
}

.fancy-line {
  background-image: url("./assets/backgrounds/fancyLine.png");
  background-position: 0px -20px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: 1px red solid;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.top-background-image {
  background-image: url("./assets/backgrounds/topBackground.png");
  background-repeat: no-repeat;
  background-position: -80px -50px;
  background-size: 125% 100%;
  /* background-position: center;
	background-size: 140% 100%; */

  /* border: 1px red solid; */
}
/* csynb */

/*CSS for ANTD Questionaire Pop Up*/
.questionaire-modal {
  margin: 0 !important;
  height: 100%;
}

.questionaire-modal .ant-modal-content {
  padding: 0;
  height: 100vh;
}

.questionaire-modal-background {
  background-image: url("./assets/backgrounds/background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  gap: 10px;
  padding: 1rem 0;
}

.questionaire-modal-main-logo {
  width: 40%;
  padding: 0.5rem;
}

.questionaire-modal-content {
  background-color: #ffffffff;
  width: 90%;
  height: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  padding: 3rem 0;
  margin: 1rem 0;
}

:where(.css-dev-only-do-not-override-usln0u).ant-modal-root
  .ant-modal-centered
  .questionaire-modal {
  max-width: 100vw;
}

.radio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.answer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0;
}

.answer-container .ant-radio-button-wrapper {
  border: 0;
}

.answer {
  width: 90%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  line-height: 16.94px;
}

.answer .ant-radio-button {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid lightgrey;
  border-radius: 25px;
}

.other-answer {
  width: auto;
  flex: 0.5;
}

.other-answer .ant-radio-button {
  border: 0;
}

.other-answer-container {
  width: 90%;
  border: 1px solid lightgrey;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.other-input {
  width: 70%;
}

.questionaire-submit-button {
  width: 70% !important;
  height: 47px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 25px;
  margin-bottom: 10px;
}

.ant-radio-group-solid
  :where(
    .css-dev-only-do-not-override-usln0u
  ).ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-radius: 25px !important;
}

.mbot-10 {
  margin-bottom: 10%;
}
